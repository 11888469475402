import NextImage, { ImageProps } from "next/image";

export function Image(props: ImageProps) {
  return (
    <NextImage
      {...props}
      draggable={false}
      onDragStart={(e) => e.preventDefault()}
      style={{
        userSelect: "none",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
      }}
    />
  );
}
