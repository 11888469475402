"use client";

import Link from "next/link";
import { Image } from "@repo/ui/image";

import { signInWithTimezone } from "@/lib/auth";

import { cn } from "@repo/ui/utils";
import DTLogo from "@repo/ui/icons/logos/DTLogo";

export function PublicLayout({
  children,
}: React.HTMLAttributes<HTMLDivElement>) {
  return <div>{children}</div>;
}

PublicLayout.Header = PublicLayoutHeader;
PublicLayout.Body = PublicLayoutBody;
PublicLayout.Footer = PublicLayoutFooter;

export function PublicLayoutHeader() {
  const signIn = () => {
    signInWithTimezone();
  };

  return (
    <header className="bg-accent-900 flex h-16 items-center justify-between overflow-hidden px-4">
      <div className="flex items-center gap-4">
        <Link
          aria-label="Sanctuary`s Diablo.trade"
          className="flex items-center"
          href="/"
          title="Sanctuary`s Diablo.trade"
        >
          <div className="relative h-12 w-12">
            <Image alt="Diablo.trade" fill src="/assets/logos/sanctuary.png" />
          </div>
          <DTLogo className="hidden sm:block" />
        </Link>
        <button
          className="items-cente bg-accent-600 flex w-[135px] justify-center gap-2 rounded-md py-1"
          type="button"
        >
          <Image
            alt=""
            className="mt-[2px]"
            height={21}
            src="/assets/logos/kofi.png"
            width={25}
          />

          <span>Support Us</span>
        </button>
      </div>
      <div>
        <button
          className="flex h-[40px] w-[190px] items-center justify-center text-lg  uppercase hover:scale-105 hover:shadow-md"
          onClick={() => {
            signIn();
          }}
          style={{
            borderImage:
              "url('/assets/buttons/v4/button-border-blue.svg') stretch",
            borderImageWidth: "auto",
            borderImageSlice: "110 0 0 0",
          }}
          type="button"
        >
          sign up/login
        </button>
      </div>
    </header>
  );
}

export function PublicLayoutBody({
  className,
  children,
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <main className={cn("mx-auto mt-4 w-full max-w-5xl p-4 py-8", className)}>
      {children}
    </main>
  );
}

export function PublicLayoutFooter() {
  return (
    <footer className="bg-accent-900 shadow-lg">
      <ul className="flex flex-col gap-2 py-4 sm:flex-row sm:justify-center">
        <li className="flex  items-center justify-center">
          <Link href="/search" className="text-center  text-xs uppercase">
            About us
          </Link>
        </li>
        <li className="flex  items-center justify-center">
          <Link href="/search" className="text-center  text-xs uppercase">
            FAQ
          </Link>
        </li>
        <li className="flex  items-center justify-center">
          <Link href="/search" className="text-center  text-xs uppercase">
            Privacy Policy
          </Link>
        </li>
        <li className="flex  items-center justify-center">
          <Link href="/search" className="text-center  text-xs uppercase">
            Cookie Policy
          </Link>
        </li>
        <li className="flex  items-center justify-center">
          <Link href="/search" className="text-center  text-xs uppercase">
            Terms of Service
          </Link>
        </li>
      </ul>
    </footer>
  );
}
